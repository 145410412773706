@import './colors.scss';
@import './base.scss';

.hqViewWrapper {
  // height: 100%;
}

.hqView {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  height: calc(100vh - #{$nav-bar-height});

  .contentContainer {
    flex: 1;
    width: 78vw;
    overflow: auto;
  }
}

.addEvent {
  overflow: auto;
}
