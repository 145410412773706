@import '../colors.scss';
@import '../base.scss';

.container {
  display: flex;
  flex: 1;
  min-height: px-to-vw(150px);
  width: 100%;
  position: relative;
}

.grid {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;

  .line {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .number {
      font-size: px-to-vw(8px);
      line-height: 1.25;
      text-align: right;
      color: rgba(#636363, 0.47);
      width: px-to-vw(14px);
      margin-right: px-to-vw(8.2px);
    }

    .border {
      flex: 1;
      border-bottom: 1px solid #eaf0f4;
    }
  }
}

.bars {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.bar {
  margin: 0 5%;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
  width: px-to-vw(7px);

  &:first-child {
    margin-left: 25%;
  }
}
