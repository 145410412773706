$break-small: 320px;
$break-large: 1025px;
$mobile-break: 480px;
$max-width: 1920px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile-break) { @content; }
}

@mixin out-of-range {
  @media only screen and (min-width: $max-width) { @content; }
}

@mixin responsive-prop($property, $mobile, $mid, $wide) {
  @media only screen and (max-width: $break-small) { #{$property}: $mobile; }
  @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { #{$property}: $mid; }
  @media only screen and (min-width: $break-large) { #{$property}: $wide; }
}

/** Usage
.classname {
  @include respond-to(handhelds) { width: 100% ;}
  @include respond-to(medium-screens) { width: 125px; }
  @include respond-to(wide-screens) { float: none; }
}
*/
