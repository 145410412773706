@import './colors.scss';
@import './base.scss';

$hq-sidebar-width: px-to-rem(310px);

.container {
  display: flex;
  overflow: auto;
  z-index: 5;
  width: $hq-sidebar-width;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  flex-direction: column;
  font-family: Product Sans;
  font-size: px-to-rem(19px);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
  padding-top: px-to-rem(65px)
}

.option {
  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: px-to-rem(28px) px-to-rem(44px);
  color: #2c2c2c;

  &:hover {
    text-decoration: none;
  }

  &.caret-down {

  }

  &.caret-up {

  }

  .icon {
    width: px-to-rem(26px);
    object-fit: contain;
    margin-right: px-to-rem(25px);
  }

  .activeIcon {
    display: none;
  }

  &.optionWithSubMenu {
    background-image: url(../assets/images/hq-sidebar-caret-down@2x.png);
    background-repeat: no-repeat;
    background-size: px-to-rem(14px) px-to-rem(8px);
    background-position: right 15% center;
  }

  &.optionWithSubMenu.activeOption {
    background-image: url(../assets/images/hq-sidebar-caret-up@2x.png);
  }

  &.activeOption {
    font-weight: bold;
    color: #3e8df1;


    &:before {
      content: "";
      width: 4px;
      background-color: #3e8df1;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      box-shadow: 0 3px 6px 0 #3d8ef0;
    }


    .icon {
      display: none;
    }

    .activeIcon {
      display: inline-block;
    }
  }
}

.subMenu {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border: 0 solid rgba(112, 112, 112, 0.15);
  flex-shrink: 0;

  &.subMenuBorder {
    border-width: 1px 0 1px 0;
  }

  .subMenuItem {
    height: px-to-rem(60px);
    line-height: px-to-rem(60px);
    padding-left: px-to-rem(44px);
    font-size: px-to-rem(17px);
    color: #2c2c2c;
  }

  .activeSubMenuItem {
    background-color: #ddecff;
  }
}
