@import './colors.scss';
@import './base.scss';

$box-margin-px: 17px / 2;
$box-margin: px-to-vw($box-margin-px);

:root {
  --max-width: 100vw;

  @include mobile {
    --max-width: #{$design-width};
  }

  @include out-of-range {
    --max-width: #{$max-width};
  }
}

.container {
  height: calc(100vh - #{$nav-total-height});
  overflow: auto;
  background-color: #F5F5F5;
  padding: px-to-rem(25.5px - $box-margin-px) px-to-rem(32px - $box-margin-px);

  @include mobile {
    padding: px-to-rem(25.5px - $box-margin-px) px-to-rem(12px - $box-margin-px);
  }
}

.titleRow > h2 {
  font-family: Product Sans;
  font-size: px-to-vw(22px);
  font-weight: bold;
  padding-left: 10px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  min-height: min-content;
  height: 100%;
  width: 100%;
  max-width: $max-width;
  max-height: calc(var(--max-width, 100vh) * 0.4);

  @include mobile {
    height: unset;
    flex-direction: column;
  }
}

.reportCol {
  display: flex;
  flex-direction: column;
}

.firstCol {
  flex: 1.26;
}

.secondCol {
  flex: 1.45;
}

.thirdCol {
  flex: 1;
}

.reportBox {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: px-to-vw(16px) px-to-vw(22px);
  font-family: Product Sans;

  .reportBoxHeader {
    display: flex;
    flex-direction: row;
    margin-bottom: px-to-vw(13px);

    @include mobile {
      margin-bottom: 0;
    }
  }

  .reportBoxKeyMetric {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px-to-vw(18px);
    padding-right: px-to-vw(10px);
    margin-right: px-to-vw(10px);
    border-right: 1px solid rgba(229, 229, 229, 0.52);
  }

  .reportBoxTitle {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    text-align: left;

    h3 {
      font-size: px-to-vw(14px);
      font-weight: bold;
      line-height: 1.21;
      color: #434343;
      margin-bottom: px-to-vw(3px);

      .keyMetric {
        font-weight: normal;
        color: rgb(120, 120, 120);
      }
    }

    h4 {
      font-size: px-to-vw(9px);
      margin-bottom: 0;
      line-height: 1.22;
      color: #636363;

      @include mobile {
        opacity: 0.66;
      }
    }
  }

  .reportBoxDropdown {
    z-index: 10;
    position: absolute;
    right: px-to-vw(22px);
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    font-size: px-to-vw(9px);
    line-height: 1.56;
    color: #4d4f5c;
    padding: px-to-vw(4px) px-to-vw(6px);
    border: 1px solid #d7dae2;

    button {
      @include reset-input;
      line-height: 1.56;
      cursor: pointer;
      padding-right: px-to-vw(14.2px);

      &.closed {
        padding-right: 0;
      }

      &.closed:after {
        content: '';
        display: inline-block;
        width: px-to-vw(8.2px);
        height: px-to-vw(5.2px);
        margin-left: px-to-vw(6px);
        background-image: url(../assets/images/dashboard-caret-down@3x.png);
        background-size: contain;
      }
    }

    span {
      display: block;
      cursor: pointer;
      margin-top: px-to-vw(6px);
    }
  }

  .reportBoxContent {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }

  .reportBoxMobileDown {
    position: absolute;
    right: 27px;

    img {
      width: px-to-vw(13.7px);
      height: px-to-vw(8.7px);
      object-fit: contain;
      opacity: 0.42;
    }
  }

  .reportBoxMobileUp {
    margin-top: px-to-vw(10px);
    text-align: center;

    img {
      width: px-to-vw(11.9px);
      height: px-to-vw(7.6px);
      object-fit: contain;
      opacity: 0.69;
    }
  }
}

.box {
  flex: 1;
  margin: $box-margin;
}

// Report specific

.reportTable {
  font-family: Product Sans;
  width: 100%;

  th {
    font-size: px-to-vw(10px);
    font-weight: bold;
    line-height: 1.3;
    text-align: left;
    color: #070707;
  }

  tbody:before {
    content: '\200C';
    display: block;
    line-height: px-to-vw(12px);
    visibility: hidden;
  }

  tbody {
  }

  tr {
  }

  td {
    font-size: px-to-vw(10px);
    line-height: 2.2;
    text-align: left;
    color: #909090;
  }

  .reportTable-colleagues img {
    height: 2vh;
    width: 2vh;
  }

  td span.critical {
    background-color: var(--salmon-pink);
    color: white;
  }

  td span.warning {
    background-color: #ffda83;
    color: white;
  }

  .reportTable-colleagues img {
    height: 2vh;
    width: 2vh;
  }
}

.overdueTableLegend {
  font-size: px-to-vw(10px);
  line-height: 2.1;
  text-align: left;
  color: #4d4f5c;
  margin-top: px-to-vw(10px);
  margin-bottom: px-to-vw(15px);

  span {
    margin-right: px-to-vw(21px);
  }

  indicator {
    margin-right: px-to-vw(5px);
  }
}

.overdueTableIndicator {
  $size: px-to-vw(7px);
  height: $size;
  width: $size;
  margin-right: px-to-vw(12px);
}

.overdueTable {
  .critical {
    color: rgba(#c7444a, 0.8);
    font-size: px-to-vw(11px);
  }

  .warning {
    color: #e8a300;
    font-size: px-to-vw(11px);
  }
}

.utilisationBox {
  flex: 1.16;
}

.utilisationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  svg:first-child {
    margin-right: px-to-vw(15px);
  }
}

.utilisationDonut {
  $size: px-to-vw(60px);
  width: $size;
  height: $size;
}

.complianceContainer {
  display: flex;
  flex-direction: row;
}

.complianceLegend {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .legendItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: px-to-vw(15px);

    h5 {
      font-size: px-to-vw(10px);
      color: #242424;
      margin-bottom: 0;
      margin-top: px-to-vw(5px);
      text-align: center;
    }

    span {
      font-size: px-to-vw(9px);
      font-weight: bold;
      line-height: 2.11;
      color: rgba(#4d4f5c, 0.62);
    }
  }
}

.complianceDonut {
  flex: 1;
  $size: px-to-vw(148px);
  width: $size;
  height: $size;
}

.storeRankBox {
  color: white;

  &.reportBox {
    background-color: #4289f2;
  }

  .reportBoxTitle h3 {
    color: white;

    .keyMetric {
      color: rgba(white, 0.7);
    }
  }

  .reportBoxDropdown {
    background-color: unset;
    color: white;
    border: 1px solid #ffffff;

    button {
      color: white;

      &.closed:after {
        background-image: url(../assets/images/store-rank-caret@3x.png);
        background-repeat: no-repeat;
      }
    }
  }

  @include mobile {
    .reportBoxMobileDown > img {
      filter: #{"grayscale() brightness(5)"};
      opacity: 1;
    }

    .reportBoxMobileUp > img {
      filter: #{"grayscale() brightness(5)"};
      opacity: 1;
    }
  }
}

.todaysReport {
  .todaysReportHeader {
    margin-bottom: px-to-vw(20px);

    .icons > td > img {
      $size: px-to-vw(15px);
      width: $size;
      height: $size;
      object-fit: contain;
      opacity: 0.81;
    }

    .headerBig > td {
      font-size: px-to-vw(25px);
      font-weight: bold;
      line-height: 1.3;
      color: #242424;
    }

    .headerSmall > td {
      font-size: px-to-vw(8px);
      line-height: 1.88;
      color: #242424;
    }
  }

  .todaysReportChart {
    display: flex;
    flex-direction: row;
    align-items: center;

    .legend {
      flex: 1;

      .title {
        font-size: px-to-vw(11px);
        color: #242424;
      }
    }

    .chartContainer {
      position: relative;
      align-self: stretch;
      flex: 1;

      .chart {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  @include mobile {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    box-shadow: unset;

    .reportBoxHeader {
      justify-content: center;
    }

    .reportTable.todaysReportTable {
      tr.icons > td > img {
        $size: px-to-vw(22px);
        height: $size;
        width: $size;
        opacity: 1;
        margin-top: px-to-vw(12px);
        margin-bottom: px-to-vw(4px);
      }

      tr.headerSmall > td {
        font-size: px-to-vw(9px);
      }

      tr.headerBig > td {
        font-size: px-to-vw(17px);
        padding-bottom: px-to-vw(10px);
      }

      & > tr:first-child > td {
        border-top-left-radius: px-to-vw(4px);
        border-top-right-radius: px-to-vw(4px);
      }

      & > tr:last-child > td {
        border-bottom-left-radius: px-to-vw(4px);
        border-bottom-right-radius: px-to-vw(4px);
      }

      & > tr {
        & > td:nth-child(odd) {
          text-align: center;
          width: 30%;
        }

        & > td {
          background-color: white;
        }

        & > td.filler {
          background-color: transparent;
        }
      }
    }

    .todaysReportChart {
      margin: 0 10%;
    }
  }
}

.taskPaceBox {
  flex: 2.27;
}

.taskPaceChart {
  flex: 1;
  display: flex;
  flex-direction: column;

  .legend {
    display: flex;
    flex-direction: row;

    .legendItem {
      margin-right: px-to-vw(18px);
      font-size: px-to-vw(10px);
      line-height: 2.11;
      color: rgba(#4d4f5c, 0.62);
    }
  }

  .chart {
    flex: 1;
    flex-direction: column;
  }
}

.storeRank {
  padding-top: px-to-vw(12px);
  padding-left: px-to-vw(12px);
  padding-bottom: px-to-vw(6px);
  background-image: url(../assets/images/store-rank-background@3x.png);
  background-size: px-to-vw(126.1px) px-to-vw(70px);
  background-repeat: no-repeat;

  .rank {
    font-size: px-to-vw(24px);
  }

  .rankClass {
    display: block;
    font-size: px-to-vw(10px);
    font-weight: bold;
  }
}
