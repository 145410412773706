@import './colors.scss';
@import './base.scss';

@mixin add-type-colors($property) {
  @each $type, $color in $task-colors {
    &.#{$type} {
      #{$property}: $color;
    }
  }
}

span.taskName {
  @include responsive-prop(font-size, 15px, 11px, 14px);
}


.modal {
  position: absolute;
  width: px-to-rem(479.6px);
  display: flex;
  flex-direction: column;
  // width: 479.6px;
  // height: 404.1px;
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
  box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.36);
  background-color: #ffffff;
  text-align: center;
  padding-top: px-to-rem(38px);
  padding-bottom: px-to-rem(27px);
  padding-right: px-to-rem(38px);
  padding-left: px-to-rem(38px);

  @include mobile {
    width: 86vw;
    padding-right: px-to-rem(20px);
    padding-left: px-to-rem(20px);
  }

  @include add-type-colors(border-color);

  .closeButton {
    @include reset-input;
    $offset: px-to-rem(19px);
    position: absolute;
    top: $offset;
    right: $offset;
    cursor: pointer;

    @include mobile {
      $offset: px-to-rem(-17.65px);
      top: $offset;
      right: $offset;
      background-color: rgba(#6e6e6e, 0.93);
      width: 35.3px;
      height: 35.3px;
      border-radius: 17.65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      $size: px-to-rem(20.8px);
      width: $size;
      height: $size;
      object-fit: contain;

      @include mobile {
        filter: invert(100%);
        $size: px-to-rem(20px);
        width: $size;
        height: $size;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(#707070, 0.13);
    padding-bottom: px-to-rem(27.5px);
  }

  .timeBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .timeBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: px-to-rem(91px);
      height: px-to-rem(59px);
      border-radius: px-to-rem(11px);
      background-color: #f0f0f0;
      margin-bottom: px-to-rem(6px);

      h4 {
        font-family: Product Sans;
        font-size: px-to-rem(17px);
        font-weight: bold;
        line-height: 1.18;
        color: #636363;
      }

      & > span {
        font-family: Product Sans;
        font-size: px-to-rem(12px);
        line-height: 1.25;
        color: rgba(#636363, 0.69);
      }
    }

    & > span {
      width: px-to-rem(63.5px);
      height: px-to-rem(20.4px);
      border-radius: px-to-rem(19px);
      background-color: var(--salmon-pink);
      font-family: Product Sans;
      font-size: px-to-rem(9px);
      font-weight: bold;
      line-height: px-to-rem(20.4px);
      text-align: center;
      color: #ffffff;

      &.inProgress {
        background-color: #10d592;
      }
    }
  }

  .infoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: px-to-rem(18px);
    margin-right: px-to-rem(24px);

    h4 {
      font-family: Product Sans;
      font-size: px-to-rem(16px);
      font-weight: bold;
      line-height: 1.19;
      text-align: left;
      color: #1f1f1f;
      margin-bottom: px-to-rem(17px);
    }

    .infoProgress {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .progress {
      width: px-to-rem(223px);
      height: px-to-rem(18px);
      border-radius: px-to-rem(10px);
      background-color: #d5d5d5;
      margin-bottom: px-to-rem(11px);
      overflow: hidden;

      @include mobile {
        width: calc(86vw - 160px);
      }

      .progressBar {
        width: 100%;
        height: px-to-rem(18px);
        border-radius: px-to-rem(10px);
        font-family: Product Sans;
        font-size: px-to-rem(9px);
        font-weight: bold;
        line-height: px-to-rem(18px);
        text-align: center;
        color: #636363;

        &.good {
          background-color: #2699fb;
          color: white;
        }

        &.withPadding span {
          padding-left: px-to-rem(5px);
        }
      }
    }

    .remaining {
      font-family: Product Sans;
      font-size: 11px;
      font-weight: bold;
      line-height: 1.27;
      text-align: center;
      color: rgba(#3b3b3b, 0.87);
    }

  }

  .body {
    display: flex;
    flex-direction: column;
    font-family: Product Sans;
    font-size: 12px;
    line-height: 1.25;
    text-align: left;
    padding-top: px-to-rem(27.5px);
    padding-right: px-to-rem(26px);
    padding-left: px-to-rem(26px);

    .item {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: bold;
        color: #1f1f1f;
        margin-right: px-to-rem(7px);
      }

      .content {
        flex: 1;
        color: #707070;
      }

      .noDesc {
        text-align: center;
      }
    }
  }

  .button {
    @include reset-input;
    width: px-to-rem(171px);
    height: px-to-rem(33.7px);
    border-radius: px-to-rem(42px);
    background-image: linear-gradient(89deg, rgba(16, 162, 227, 0.94), #517df8);
    font-family: Product Sans;
    font-size: px-to-rem(12px);
    font-weight: bold;
    line-height: px-to-rem(33.7px);
    color: #ffffff;
    align-self: center;
    margin-top: px-to-rem(44px);
    cursor: pointer;

    &.disabled {
      cursor: no-drop;
      opacity: 0.7;
    }
  }

  .categoryIcon {
    position: absolute;
    top: px-to-rem(15px);
    left: px-to-rem(15px);
    $size: px-to-rem(17px);
    width: $size;
    height: $size;
  }

  .completePrompt {
    margin-bottom: px-to-rem(14px);

    & > .message {
      font-family: Product Sans;
      font-size: px-to-rem(19px);
      line-height: 1.21;
      text-align: center;
      color: #636363;
    }

    & .options > .image {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      span {
        display: block;
        font-family: Product Sans;
        font-size: px-to-rem(17px);
        font-weight: bold;
        line-height: 1.18;
        text-align: center;
        color: rgba(#171717, 0.87);
      }
    }

    & .image > img {
      $size: px-to-rem(63.8px);
      height: $size;
      width: $size;
      margin: px-to-rem(13px);
      margin-top: px-to-rem(29px);
    }

    & > .options {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    & > .options img {
      cursor: pointer;
    }
  }

  .finished {
    text-align: center;
    font-family: Product Sans;
    color: #706e6b;
    margin-top: 10px;
    margin-bottom: 30px;

    & > .message {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      color: #363636;
      margin-bottom: 6px;
    }

    & > .duration {
      font-size: 12px;
      line-height: 1.25;
      color: #636363;
    }

    & .successImage > img {
      width: 138.8px;
      height: 119.7px;
      object-fit: contain;
      margin-bottom: 39px;
    }

    & .failImage > img {
      width: 119.7px;
      height: 119.7px;
      object-fit: contain;
      margin-bottom: 39px;
    }

    .finishedIn {
      font-size: 20px;
      color: var(--salmon-pink);
    }

    .finishedIn.ahead {
      color: #10d592;
    }

  }

  .notFinisihedReasons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .notFinisihedReason {
      height: 35px;
      border-radius: 35px;
      border: 1px solid #707070;
      font-size: 11px;
      line-height: 35px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      margin: 5px;
      color: rgba(#383838, 0.56);
      cursor: pointer;

      &.selected {
        background-color: #707070;
        color: white;
      }
    }
  }


  // h3 {
  //   font-family: Product Sans;
  //   font-size: px-to-rem(20px);
  //   font-weight: bold;
  //   line-height: 1.2;
  //   color: #363636;
  //   margin-bottom: px-to-rem(26px);
  // }

  .illegalReason {
    font-family: Product Sans;
    font-size: px-to-rem(15px);
    line-height: 1.2;
    color: #5d5d5d;
    width: 60%;
    margin-bottom: px-to-rem(8px);
  }
}
