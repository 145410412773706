@import '../colors.scss';
@import '../base.scss';

$height: 3.8px;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Product Sans;
  font-size: px-to-vw(8px);
  line-height: 1.13;
  text-align: left;
  color: #656565;
}

.total {
  width: px-to-vw(63.1px);
  height: px-to-vw($height);
  border-radius: px-to-vw($height / 2);
  background-color: #dedede;
  margin-right: px-to-vw(8px);
}

.progress {
  width: px-to-vw(63.1px);
  height: px-to-vw($height);
  border-radius: px-to-vw($height / 2);
  background-color: #4dad88;
}
