@import './colors.scss';
@import './base.scss';

.container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  height: $nav-total-height;
  z-index: 60 * 24 + 10;
  background-color: white;
  align-items: center;
}

.noTabBarContainer {
  height: $nav-bar-height;
}

.bar {
  height: $nav-bar-height;
  width: 100%;
  max-width: $max-width;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.logo {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: px-to-rem(29px);
  margin-left: px-to-rem(29px);

  img {
    width: px-to-rem(68.1px);
    height: px-to-rem(16.6px);
    object-fit: contain;
  }
}

.barItems {
  display: flex;
  margin-right: px-to-rem(29px);
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-top: px-to-rem(12.5px);
  margin-bottom: px-to-rem(12.5px);
  border-right: 1px solid rgba(#e5e5e5, 0.52);

  input {
    @include reset-input;
    font-family: Product Sans;
    font-size: px-to-rem(16px);
    line-height: 1.19;
    letter-spacing: 0.2px;
    text-align: left;
    color: darken(#969696, 30%);
    width: px-to-rem(65px);
    margin-left: px-to-rem(20px);
    margin-right: px-to-rem(20px);
    transition: width 0.3s ease;

    &::placeholder {
      color: #969696;
    }

    &:focus {
      width: px-to-rem(135px);
    }
  }

  img {
    width: px-to-rem(15.7px);
    height: px-to-rem(15.6px);
    object-fit: contain;
  }
}

.userMenuContainer {
  display: flex;
  align-items: center;

  .avatar {
    $size: px-to-rem(40.5px);
    margin-left: px-to-rem(34.4px);
    margin-right: px-to-rem(17px);
    width: $size;
    height: $size;
    object-fit: contain;
    border-radius: $size / 2;
  }

  button {
    @include reset-input;
    font-family: Product Sans;
    font-size: px-to-rem(16px);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0.2px;
    text-align: left;
    color: #969696;
    cursor: pointer;

    &:after {
      content: "";
      display: inline-block;
      width: px-to-rem(12.5px);
      height: px-to-rem(9px);
      margin-left: px-to-rem(10.6px);
      margin-right: px-to-rem(10.6px);
      background-image: url(../assets/images/dashboard-caret-down@3x.png);
      background-size: contain;
    }
  }
}

.dropdownContainer {
  position: relative;
  align-self: flex-end;
  height: px-to-rem(10px);
  visibility: hidden;
  transition-property: opacity, transform, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  opacity: 0;
  transform: translateY(- px-to-rem(10px));
  z-index: 60 * 24 + 15;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: px-to-rem(168.6px);
  top: 0;
  right: 0;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(44.7px);
  backdrop-filter: blur(44.7px);
  box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.33);
  background-color: #ffffff;
  padding: px-to-rem(20px);

  a {
    flex: 1;
    display: block;
    color: black;
    white-space: nowrap;
    opacity: 0.57;
    font-family: Product Sans;
    font-size: px-to-rem(15px);
    line-height: 1.2;
    color: #393939;
    margin-bottom: px-to-rem(12px);

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: inline-block;
      height: px-to-rem(16px);
      object-fit: contain;
      margin-right: px-to-rem(20px);
    }
  }
}

.tabs {
  height: $nav-tabs-height;
  width: 100%;
  max-width: $max-width;
  background-color: white;
  display: flex;
  flex-direction: row;

  a:last-child {
    border-right: none;
  }
}

a.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Product Sans;
  font-size: px-to-rem(19px);
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.2px;
  text-align: left;
  color: #6e6e6e;
  border-top: 1px solid rgba(#707070, 0.07);
  border-right: 1px solid rgba(#707070, 0.07);

  &:hover {
    text-decoration: none;
    color: inherit;
  }
  //
  // &:last-child {
  //   border-right: none;
  // }

  img {
    $size: px-to-rem(25px);
    width: $size;
    height: $size;
    object-fit: contain;
    margin-right: px-to-rem(15px);
  }

  .tabIcon {
    display: inline-block;
  }

  .tabActiveIcon {
    display: none;
  }

  &.active {
    color: white;
    background-image: linear-gradient(89deg, rgba(16, 162, 227, 0.94), #517df8);

    .tabIcon {
      display: none;
    }

    .tabActiveIcon {
      display: inline-block;
    }
  }

}
