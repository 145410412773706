@import './colors.scss';
@import './base.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #f8f8f8;
  z-index: 24 * 60 + 5;
  width: 100%;
}

.innerContainer {
  width: 100%;
  max-width: $max-width;
  display: flex;
  flex-direction: row;
  height: px-to-rem(52px);
}

.day {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: Product Sans;
  font-size: px-to-rem(20px);
  line-height: 1.2;
  color: #000000;
  cursor: pointer;
}

.activeDay {
  color: #4dad88;
}
