@font-face {
    font-family: 'Product Sans';
    src: url('../assets/fonts/ProductSans-Regular.eot');
    src: url('../assets/fonts/ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/ProductSans-Regular.woff2') format('woff2'),
        url('../assets/fonts/ProductSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../assets/fonts/ProductSans-Bold.eot');
    src: url('../assets/fonts/ProductSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/ProductSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/ProductSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.eot');
    src: url('../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
