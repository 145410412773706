@import './colors.scss';
@import './base.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: Product Sans;
  font-size: px-to-rem(41px);
  line-height: 1.2;
  color: #000000;
  margin-top: px-to-rem(79px);
  margin-bottom: px-to-rem(44px);
}

.box {
  display: flex;
  align-items: center;
  border-radius: 13px;
  background-color: #ffffff;
  display: flex;
  width: 92%;
  flex-direction: column;
  // min-height: 80vh;
  margin-bottom: px-to-rem(50px);
  padding: 0 px-to-rem(15px);

  .content {
    width: calc(100% - 30px);
    max-width: px-to-rem(984px);
  }

  label {
    font-family: Product Sans;
    font-size: 19px;
    font-weight: bold;
    line-height: 1.21;
    color: rgba(#434343, 0.72);
    padding-top: px-to-rem(20px);
  }

  input {
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: px-to-rem(30px) 0;

    &.left {
      justify-content: flex-start;
    }
  }

  .button {
    cursor: pointer;
    background-color: #3e8df1;
    border-color: #3e8df1;
    margin: 0 px-to-rem(10px);

    &.green {
      background-color: #10d592;
      border-color: #10d592;

      &:active, &:hover {
        background-color: #10d592;
        border-color: #10d592;
        box-shadow: unset;
      }
    }
  }
}

.rangePicker {
  position: absolute;
  top: 0;
}

.progressContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Product Sans;
  $size: px-to-rem(50px);
  margin-top: px-to-rem(80px);
  margin-bottom: px-to-rem(20px);

  .border {
    position: absolute;
    top: $size / 2;
    border-bottom: 3px dotted #3e8df1;
  }

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &.done {
      cursor: pointer;

      &:hover .text {
        text-decoration: underline;
      }
    }

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $size;
      height: $size;
      border-radius: $size / 2;
      background-color: #3e8df1;
      color: white;
      font-size: px-to-rem(22px);
      margin-bottom: px-to-rem(20px);

      &.active {
        background-color: #10d592;
      }

      &.done {
        color: rgba(0, 0, 0, 0);
        background-image: url(../assets/images/add-event-checkmark@2x.png);
        background-size: px-to-rem(23.7px) px-to-rem(16.7px);
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .text {
      font-size: 21px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.formRow {
  display: flex;
  flex-direction: row;
  overflow: visible;
}

.formCol {
  flex: 1 1;
  overflow: visible;
  padding-right: 10px;
  padding-left: 10px;

  &.gray {
    background-color: #f7f7f7;
    border-radius: 3px;
  }
}

.pillBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 3px;
  border: 1px #a3a3a3 solid;
  padding: px-to-rem(13px) px-to-rem(12px);
  align-content: flex-start;

  &.selectedStores {
    min-height: px-to-rem(400px);
  }

  &.tasks {
    min-height: px-to-rem(233px);
    width: 100%;
    display: block;

    .pill {
      width: 33.333%;
      float: left;
    }
  }

  &.approvers {
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: px-to-rem(400px);

    span.delete {
      float: right;
    }
  }
}

.pill {
  display: flex;
  flex-direction: row;
  height: px-to-rem(34.5px);
  line-height: px-to-rem(34.5px);
  padding: 0 px-to-rem(12px);
  margin: px-to-rem(6px) px-to-rem(8.5px);
  border-radius: 22px;
  background-color: #dddbda;
  font-family: Product Sans;
  font-size: px-to-rem(14px);
  text-align: left;
  color: #706e6b;
  cursor: pointer;

  span:first-child {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.delete {
    margin-left: px-to-rem(8px);
    cursor: pointer;
  }
}

.dropZone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 15px;

  &.accept {
    border-color: #10d592;
  }

  &.reject {
    border-color: red;
  }

}

.uploadSign img {
  width: 84px;
  height: 82.5px;
}

.formError {
  color: #dc3545;
  font-size: px-to-rem(12px);
  line-height: px-to-rem(25px);
  margin-bottom: px-to-rem(-25px);
  margin-left: px-to-rem(5px);
}
