$max-width: 1920px;
$max-height: 1080px;

@function px-to-rem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

$design-width: 1024px;

@function px-to-vw($size) {
  $vwSize: $size / $design-width;
  @return calc(#{$vwSize} * var(--max-width, 100vw));
}

@mixin reset-input {
  margin: 0;
	border: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	background: none;
	line-height: 1;
  -webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;

  &:focus {
    outline: 0;
  }
}

@mixin background-image-spaced($width, $height, $margin-right, $image) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="#{$width + $margin-right}" height="#{$height}"><image width="#{$width}" height="#{$height}" xlink:href="data:image/png;base64,#{$image}" /></svg>');
}
