.event-form-wrapper {
  overflow-y: scroll;
  margin: 10px;
}

.event-form-col {
}

.event-form-button {
  margin-top: 15px;
  cursor: pointer;
}

.event-form-button.disabled {
  cursor: not-allowed;
}

.event-form-dropzone {
  width: 100%;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  padding: 20px;
}

.event-form-dropzone-accept {
  border-color: green;
  border-style: solid;
}

.event-form-dropzone-reject {
  border-color: red;
  border-style: solid;
}
