@import './base.scss';
@import './colors.scss';

.container {
  font-family: Product Sans;
  margin: 60px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 2.05fr 1.42fr;
  height: calc(100% - 120px);

  & > div {
    border-radius: 5px;
    box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
  }

  .firstRow, .thirdRow {
    grid-column: span 2;
  }

  .secondRow {
    grid-column: span 3;
  }
}

.firstRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 40px 29px 40px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #171717;


    img {
      width: 56.6px;
      height: 56.6px;
      object-fit: contain;
      margin-right: 26px;
    }
  }

  .progressContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 19px;
    color: #555555;
  }

  .progressBar {
    flex: 1 1;
    height: 17px;
    border-radius: 8.5px;
    background-color: rgba(#bebebe, 0.59);
    margin-right: 13px;

    .progress {
      height: 17px;
      border-radius: 8.5px;

      &.blue {
        background-color: #0071ff;
      }

      &.red {
        background-color: #e53c5c;
      }

      &.green {
        background-color: #10d592;
      }
    }
  }

  .footer {
    text-align: center;
    font-size: 22px;
    color: #555555;
  }
}

.complianceReportBox {
  box-shadow: unset;
  height: 100%;
}

.thirdRow {
  padding: 22px 25px;

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #434343;
    margin-bottom: 30px;
  }

  .body {
    display: flex;
    flex-direction: column;
  }
}

.approvals {
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
  }

  .eventCol {
    flex: 1 1;
    display: flex;
    flex-direction: column;

    .eventTitle {
      font-size: 17px;
      line-height: 1.18;
      text-align: left;
      color: #1d1d1d;
      margin-bottom: 3px;
    }

    .creator {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;
      line-height: 1.15;
      color: rgba(#4e4e4e, 0.73);
    }

    .initials {
      display: inline-block;
      width: 31.3px;
      height: 31.3px;
      line-height: 31.3px;
      border-radius: (31.3px / 2);
      background-color: #ffe0e6;
      font-size: 12px;
      letter-spacing: 0.6px;
      text-align: center;
      color: #b93f56;
      margin-right: 8px;
    }
  }

  .button {
    width: 71px;
    height: 25px;
    border-radius: 22px;
    background-color: #3e8df1;
    border-color: #3e8df1;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    padding: 0 5px;

    &.secondary {
      border-color: #cbcbcb;
      background-color: #ffffff;
      color: #656565;
      margin-left: 6px;
    }
  }

  .noApprovals {
    flex: 1 1;
    padding-top: 25px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    color: rgba(#434343, 0.55);
  }
}

.topAlerts {
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  grid-row-gap: 17px;

  .store {
    font-size: 19px;
    line-height: 1.37;
    color: #121212;
  }

  .name {
    font-size: 19px;
    line-height: 1.37;
    color: rgba(#121212, 0.52);
  }

  .status {
    font-size: 14px;
    text-align: center;
    padding: 4px;

    &.critical {
      color: #e53c5c;
      background-color: #ffe0e6
    }

    &.warning {
      color: #ffa700;
      background-color: #ffe6aa;
    }
  }
}

.recentActivities {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(#707070, 0.09);
    padding: 14px 0;
    justify-content: space-between;

    .activity {
      font-size: 14px;
      line-height: 1.21;
      text-align: left;
      color: #706e6b;
    }

    .time {
      flex-shrink: 0;
      font-size: 12px;
      line-height: 1.25;
      color: #2485ff;
      text-align: right;
    }
  }
}
