@import './base.scss';
@import './colors.scss';

.container {
  display: grid;
  margin: 60px;
  grid-template-rows: 40px 1fr 5.85fr;
  grid-template-columns: 1fr 1.13fr;
  height: calc(100% - 120px);
  grid-gap: 30px;
  font-family: Product Sans;

  & > div > div {
    background-color: white;
  }
}

.titleRow > h2 {
  font-size: 35px;
  font-weight: bold;
  padding-left: 10px;
}

.firstRow {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: repeat(4, 1fr);
  border: solid 1px #d3d3d3;

  & > div {
    border-right: solid 1px #d3d3d3;
  }

  & > div:last-child {
    border-right: none;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 25px;
  padding-left: 38px;
  padding-bottom: 24px;

  .title {
    font-size: 18px;
    line-height: 1.22;
    color: #636363;
  }

  .number {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    color: #434343;
  }

  .ticker {
    margin-left: 20px;
  }
}

.firstCol, .secondCol {
  & > div {
    box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.04);
    border-radius: 7px;
  }
}

.firstCol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.32fr;
  height: 100%;
  grid-gap: 30px;
}

.merge {
  grid-column: 1 / 3;
}

.secondCol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 3.26fr 2.98fr;
  height: 100%;
  grid-gap: 30px;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
}

.boxHeading {
  font-size: 26px;
  font-weight: bold;
  color: #434343;
}

.boxSubHeading {
  font-size: 15px;
  color: #636363;
}

.eventsCompliance {
  display: flex;
  flex-direction: row;
  align-items: center;

  .legend {
    flex: 2;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-gap: 10px 20px;
    width: 100%;
    height: min-content;
    margin-right: 30px;
    font-size: 20px;
    color: #242424;

    .number {
      font-size: 18px;
      font-weight: bold;
      color: #4d4f5c;
    }
  }

  .chart {
    flex: 1;
    padding: 5%;
  }
}

.overdueTasks {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15%;

  .legend {
    display: flex;
    flex-direction: row;

    & > div {
      flex: 1;
    }

    span {
      font-size: 18px;
      line-height: 2.11;
      color: #4d4f5c;
      margin-left: 14px;
    }
  }

  .table {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;

    span {
      font-size: 18px;
      line-height: 1.72;
      color: #909090;
      margin-left: 23px;
    }
  }
}

.keyMetric {
  font-size: 28px;
  font-weight: bold;
  color: #171717;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 33px;

  img {
    width: 47.7px;
    height: 47.7px;
    object-fit: contain;
    margin-right: 19px;
  }
}

.topAreas {
  flex: 1;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .row {
    display: flex;
    flex-direction: row;
  }

  .name {
    flex: 1;
    font-size: 18px;
    line-height: 1.72;
    color: #191919;
  }
}

.completedOnTime {
  flex-direction: row;

  .sidebar {
    flex: 1;
  }

  .numbers {
    margin-top: 29px;

    & > span {
      font-size: 34px;
      font-weight: bold;
      color: #434343;
      margin-right: 17px;
    }
  }

  .chart {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
