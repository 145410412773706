.CalendarDay__selected_span {
  background: lighten(#3e8df1, 25%);
  border: 1px solid #e4e7e7;
  color: white;
}

.CalendarDay__selected {
  background: #3e8df1;
  color: white;
}

.CalendarDay__selected:hover {
  background: #3e8df1;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #3e8df1;
}
