@import './base.scss';
@import './colors.scss';

.container {
  display: grid;
  margin: 60px;
  grid-template-rows: 40px 1fr 3.4fr 1.68fr;
  height: calc(100% - 120px);
  grid-gap: 30px;
  font-family: Product Sans;

  & > div > div {
    background-color: white;
  }
}

.titleRow > h2 {
  font-size: 35px;
  font-weight: bold;
  padding-left: 10px;
}

.firstRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: solid 1px #d3d3d3;

  & > div {
    border-right: solid 1px #d3d3d3;
  }

  & > div:last-child {
    border-right: none;
  }
}

.secondRow {
  display: grid;
  grid-template-columns: 2.02fr 1.07fr 1fr;
  grid-gap: 38px;
}

.thirdRow {
  display: grid;
  grid-template-columns: 1.34fr 1fr 1.66fr;
  grid-gap: 38px;
}

.secondRow, .thirdRow {
  & > div {
    box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.04);
    border-radius: 7px;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 25px;
  padding-left: 38px;
  padding-bottom: 24px;

  .title {
    font-size: 18px;
    line-height: 1.22;
    color: #636363;
  }

  .number {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    color: #434343;
  }

  .ticker {
    margin-left: 20px;
  }
}

.todaysReport {
  display: flex;
  padding: 48px;
  flex-direction: column;
  align-items: stretch;

  .todaysReportHeader {
    margin-bottom: px-to-rem(20px);

    .icons > td > img {
      $size: px-to-rem(27px);
      width: $size;
      height: $size;
      object-fit: contain;
      opacity: 0.81;
    }

    .headerBig > td {
      font-size: px-to-rem(46px);
      font-weight: bold;
      line-height: 1.3;
      color: #242424;
    }

    .headerSmall > td {
      font-size: px-to-rem(18px);
      line-height: 1.88;
      color: #242424;
    }
  }

  .todaysReportChart {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .legend {
      flex: 1;

      .title {
        font-size: px-to-rem(21px);
        color: #242424;
      }
    }

    .chartContainer {
      position: relative;
      align-self: stretch;
      flex: 1;

      .chart {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 20px 0;
      }
    }
  }
}

.reportTable {
  font-family: Product Sans;
  width: 100%;

  tbody:before {
    content: '\200C';
    display: block;
    line-height: px-to-rem(24px);
    visibility: hidden;
  }

  td {
    font-size: px-to-rem(22px);
    line-height: 2.2;
    text-align: left;
    color: #909090;
  }
}

.box {
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
}

.boxHeading {
  font-size: 26px;
  font-weight: bold;
  color: #434343;
}

.boxSubHeading {
  font-size: 15px;
  color: #636363;
}

.topAreas {
  flex: 1;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .row {
    display: flex;
    flex-direction: row;
  }

  .name {
    flex: 1;
    font-size: 18px;
    line-height: 1.72;
    color: #191919;
  }
}

.overdueTasks {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15%;

  .legend {
    display: flex;
    flex-direction: row;

    & > div {
      flex: 1;
    }

    span {
      font-size: 18px;
      line-height: 2.11;
      color: #4d4f5c;
      margin-left: 14px;
    }
  }

  .table {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;

    span {
      font-size: 18px;
      line-height: 1.72;
      color: #909090;
      margin-left: 23px;
    }
  }
}

.utilisationContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg:first-child {
    margin-right: px-to-rem(26px);
  }
}

.utilisationDonut {
  $size: px-to-rem(95px);
  width: $size;
  height: $size;
}

.rankContainer {
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to bottom, #03b8cb, #03b8cb 0%, #3e8df1 100%, #beff3f);

  .rank {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 26px 40px;
    background-image: url(../assets/images/store-rank-background@3x.png);
    background-size: px-to-rem(214.3px) px-to-rem(119px);
    background-position: 54% 58%;
    background-repeat: no-repeat;

    &:first-child {
      border-right: 1px solid rgba(white, 0.33);
    }
  }

  .heading {
    flex: 1;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    color: #ffffff;
  }

  .number {
    font-size: 45px;
    line-height: 1.2;
    color: #ffffff;
    padding-left: 20px;
  }

  .type {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.18;
    color: #ffffff;
    padding-left: 20px;
    margin-bottom: 33px;
  }
}

.taskPaceContainer {
  display: flex;
  flex-direction: row;
  padding: 20px;

  .sidebar {
    margin-left: 10px;
    margin-right: 20px;
  }

  .ticker {
    margin-top: 60%;
  }

  .graph {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
