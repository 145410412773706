@import "./util.scss";
@import "./media.scss";

$nav-bar-height: px-to-rem(78.5px);
$nav-tabs-height: px-to-rem(56.5px);
$nav-total-height: $nav-bar-height + $nav-tabs-height;

$timeline-slider-height: px-to-rem(52px);
$timeline-controls-horizontal-margin: px-to-rem(39.5px);
$timeline-controls-horizontal-margin-mobile: px-to-rem(21px);
