@import '../colors.scss';
@import '../base.scss';

.text {
  $size: 8px;
  font-family: Product Sans;
  text-anchor: middle;
  font-size: $size;
  font-weight: bold;
  text-align: center;
  fill: #262626;
  transform: translateY(#{$size / 3});

  &.withSubtitle {
    transform: translateY(#{$size / 10});
  }
}

.subtitle {
  $size: 4.5px;
  font-family: Product Sans;
  text-anchor: middle;
  font-size: $size;
  text-align: center;
  fill: rgba(#636363, 0.47);
  transform: translateY(#{$size * 1.5});
}
