html {
  height: 100vh;
  overflow: hidden;
}

body {
  background-color: #f7f7f7;
  height: 100vh;
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 2000;
}

.hq-view {
  display: flex;
  flex-direction: row;
  background-color: #F0F2F5;
}

.hq-view .hq-sidebar {
  width: 20vw;
  flex-shrink: 0;
}

.hq-sidebar-slide {
  margin-top: 70px;
}

.hq-sidebar-box {
  background-color: var(--salmon-pink);
  margin-top: 2vw;
  margin-left: 2vw;
  margin-right: 1vw;
  margin-bottom: 2vw;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1vw;
}

.hq-view .dashboard-container {
  flex: 1;
  width: 78vw;
  border-radius: 15px;
  background-color: white;
  margin: 2vw 1vw;
  height: 75vh;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 20px;
  width: 42vw;
  /* height: 65vh; */
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.login-wrapper label {
  font-family: Product Sans;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #636363;
}

.login-wrapper input[type=text], .login-wrapper input[type=password] {
  width: 38vw;
  height: 48px;
  border-radius: 20px;
  background-color: #ffffff;
  border: solid 1px #707070;
}

.login-screen-bg {
  background: url(./assets/images/ja-ma-216495-unsplash.png) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 30px auto; */
  /* display: inline-block; */
  flex-direction: column;
}

.login-screen {
  justify-content: center;
  align-items: center;
}

.login-screen input {
  border-color: #7c7c7c;
  border-width: 1px;
  border-radius: 15px;
}

.login-screen label {
  color: #636363;
}

.login-form {
  /* min-width: 300px */
}

.login-button {
  margin-top: 30px;
  cursor: pointer;
  height: 48px;
  border-radius: 15px;
  background-color: var(--salmon-pink);
  font-family: Product Sans;
  font-size: 31px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-align: center;
}


@media only screen and (max-width : 480px) {
  .login-wrapper {
    text-align: left;
    padding: 10px;
    width: 90vw;
    border-radius: 15px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  }

  .login-wrapper label {
    font-size: 22px;
  }

  .login-wrapper input[type=text], .login-wrapper input[type=password] {
    width: 80vw;
    height: 40px;
    border-radius: 16px;
    background-color: #ffffff;
    border: solid 1px #707070;
  }

  .login-button {
    margin-top: 16px;
    font-size: 22px;
    height: 40px;
  }
}

.header-navbar {
  background-color: var(--salmon-pink) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding-left: 0;
}

.hq-view .header-navbar {
  flex-basis: 92px;
}

.header-logo-wrapper {
  width: 207px;
  height: 64px;
  background-color: #c7444a;
  display: flex;
  justify-content: center;
  margin-left: 24px;
}

.header-logo {
  object-fit: cover;
  height: 100%;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard-bottom-menu {
  display: flex;
  flex-direction: row;
  margin-left: 18.5px;
  margin-right: 18.5px;
  margin-top: 8px;
  margin-bottom: 5px;
  height: 7.11vh;
}

.dashboard-bottom-menu-button {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #007bff;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 15px;
  background-color: var(--salmon-pink);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.dashboard-bottom-menu-button > a {
  color: white;
  width: 100%;
  display: inline-block;
  font-family: Product Sans;
  font-size: 6.66vh;
  line-height: 1;
}

.dashboard-bottom-menu-button > a > img{
  height: 3.4vh;
  vertical-align: middle;
  margin-right: 10px;
}

.dashboard-bottom-menu-button > a:hover {
  color: white;
  text-decoration: none;
}

.dashboard-logout-button {
  height: 3.43vh;
  width: 3.43vh;
}

.dashboard-search-box {
  display: block;
  margin: 0.5rem 1rem;
  height: 4.17vh;
  width: 16.4vw;
  padding-left: 35px;
  border: none;
  border-color: transparent;
  border-radius: 10px;
  background: url(./assets/images/Group 1446.png) no-repeat scroll calc(4.17vh/4) calc(4.17vh/4) white;
}

.navbar {
  padding: 0;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-more {
  display: none;
}

@media only screen and (max-width : 480px) {

  .navbar ul.nav {
    display: none;
    background-color: var(--salmon-pink);
    width: 100%;
  }

  .navbar ul.navbar-nav {
    display: none;
    background-color: var(--salmon-pink);
    width: 100%;
  }

  .navbar ul.navbar-visible {
    display: inherit;
  }

  .navbar ul.nav > li:first-child {
    flex: 1;
  }

  .dashboard-search-box {
    width: 90%;
  }

  .dashboard-bottom-menu-button > a {
    font-size: inherit;
    line-height: inherit;
  }

  .dashboard-bottom-menu-button > a > img {
    margin-right: 0;
  }

  .dashboard-bottom-menu-button span {
    display: none;
  }

  .navbar-more {
    display: block;
    flex: 1;
    text-align: right;
    margin-right: 5vw;
  }

}

/* Here Be Dragons */
.btn-primary {
    color: #fff;
    background-color: #FF7573;
    border-color: #FF7573;
}

/* End */

.form-control-label {
  margin-top: 5px;
}

.runway-container {
  flex: 1;
  position: relative;
  flex-direction: column;
  display: flex;
  height: calc(100vh - 8.4375rem);
}

.runway-perspective-container {
  flex: 1;
  perspective: 150px;
  perspective-origin: top;
  display: flex;
  margin: 0 18.5px;
}

.runway-perspective {
  display: flex;
  flex: 1;
  transform: rotateX(20deg);
  transform-origin: bottom;
  border-left: 1px solid white;
}

.runway-perspective-tiles {
  flex: 1;
  border-right: 1px solid white;
  background: linear-gradient(to bottom, rgba(180, 195, 207,0.8) 0%, rgba(180, 195, 207,0.7) 100%);
  position: relative;
}

.runway-titles-container {
  flex-direction: row;
  display: flex;
  border-left: 1px solid #c7c5c7;
  margin: 0 18.5px;
  position: relative;
}

.runway-lane-switch-arrow {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}

.plan-board > .runway-lane-switch-arrow {
  bottom: 1vh;
}

.runway-titles-container > .runway-lane-switch-arrow {
  top: 0;
}

.runway-lane-switch-arrow > img {
  height: 8vh;
  width: 8vh;
}

.arrow-left {
  left: -4vh;
}

.arrow-right {
  right: -4vh;
}

.runway-days-container {
  margin: 0 18.5px;
}

.runway-title {
  flex: 1;
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(124, 142, 159, 1);
}

.runway-title-name {
  height: 3.7vh;
  color: white;
  font-family: Product Sans;
  font-size: 2.6vh;
  background-color: #52e1a3;
}

.runway-title-name-mid {
  background-color: #ffda83;
  color: gray;
}

.runway-title-name-regular {
  background-color: #52e1a3;
}

.runway-title-name-danger {
  background-color: var(--salmon-pink);;
}

.runway-title-show-lane-info {
  cursor: pointer;
  color: white;
  height: 3.5vh;
}

.runway-title-show-lane-info.reverse {
  cursor: pointer;
  color: white;
  height: 3.5vh;
  transform: rotate(180deg);
}

.runway-title:last-child {
  border-right: 0 solid rgba(124, 142, 159, 1);
}

.runway-title-info {
  display: flex;
  flex-direction: row;
  height: 5.5vh;
}

.runway-title-info-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.runway-title-info-box > span {
  display: block;
  color: #909090;
  font-family: Product Sans;
}

.runway-title-info-box-number {
  font-size: 2.98vh;
  font-weight: bold;
  line-height: 1.2;
}

.runway-title-info-box-type {
  font-size: 1.5vh;
}

.runway-controls-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #ccc;
  font-size: 28px;
  font-weight: bold;
  margin: 0 18.5px;
  overflow: hidden;
}

.rc-slider {
  display: flex;
  height: 10vh;
  margin: 0;
  border: 1px solid white;
  border-radius: 0;
}

.runway-controls-months {
  display: flex;
  overflow: hidden;
  padding-top: 3px;
  padding-bottom: 3px;
}

.runway-controls-months > button {
  margin-left: 2px;
  margin-right: 2px;
  width: 7.6vw;
  cursor: pointer;
  flex-shrink: 0;
}

.runway-item-tether {
  position: relative;
  width: 1px;
  height: 1px;
  margin: auto;
}

.runway-item-tip {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  width: 12vw;
  height: 13vh;
  cursor: pointer;
}

.runway-item-tip > .runway-item-header {
  width: 100%;
  background-color: red;
  border-radius: 5px 5px 0 0;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
}

.runway-item-tip > .runway-item-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.runway-mark-tether {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: white;
}

.runway-month-mark {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  transform: translateX(-25px);
}

.runway-month-mark > span {
  color: white;
  font-size: 2.9vh;
  font-family: Product Sans;
  margin-right: 6px;
}

.runway-now-mark-tether {
  background-color: var(--salmon-pink);;
  height: 2px;
}

.runway-now-mark {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
}

.runway-now-mark > span {
  color: var(--salmon-pink);;
  text-align: right;
  font-weight: bold;
  font-size: 2.9vh;
  transform: translateX(-17px);
}

.runway-item-tip:after, .runway-item-tip:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
  transform: inherit;
}

.runway-item-tip:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #ffffff;
	border-width: 6px;
	margin-left: -6px;
}

.runway-item-tip:before {
	border-color: rgba(0, 0, 0, 0);
	border-width: 7px;
	margin-left: -7px;
}

.runway-dragging {
  cursor: grabbing;
}

.runway-lane-info-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  word-break: break-word;
}

.runway-lane-info {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.runway-lane-info-content {
  flex: 1;
  display: flex;
  overflow: auto;
}

.runway-lane-info-box {
  min-height: min-content;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.runway-lane-info-close-button {
  position: absolute;
  top: 1vh;
  right: 1vh;
  cursor: pointer;
}

.runway-lane-info-close-button > img {
  height: 2.16vh;
  width: 2.16vh;
  vertical-align: top;
}

.runway-lane-info-avatar {
  width: 3.95vh;
  height: 3.95vh;
  border-radius: 1.97vh;
  align-self: center;
  margin: 0.65vh;
}

.runway-lane-info-heading {
  font-family: Product Sans;
  font-size: 2.37vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #909090;
}

.runway-lane-info-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh;
}

.runway-lane-info-stats-stat {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.runway-lane-info-stats-number {
  font-family: Product Sans;
  font-size: 2.63vh;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #706e6b;
}

.runway-lane-info-stats-title {
  font-family: Product Sans;
  font-size: 1.71vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #909090;
}

.runway-lane-info-body-row {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
}

.runway-lane-info-body-number {
  flex: 1;
  font-family: Product Sans;
  font-size: 2.63vh;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #706e6b;
}

.runway-lane-info-body-tasks {
  flex: 3;
  display: flex;
  flex-direction: column;
  font-family: Product Sans;
  font-size: 1.45vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
  margin-top: 0.7vh;
}

.runway-lane-info-body-title {
  font-family: Product Sans;
  font-size: 2.1vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #909090;
}

.runway-lane-info-availability {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Product Sans;
  font-size: 2.1vh;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #909090;
  height: 5.66vh;
  margin: 3px;
}

.Popover {
  z-index: 999999;
}

.Popover-body {
  min-height: 20vh;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 80vw;
}

.popover-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.popover-close-button-container {
  margin-left: 15px;
  transform: translateX(10px) translateY(-10px);
}

.popover-close-button {
  cursor: pointer;
}

.Popover-body > div {
  flex: 1;
  margin-bottom: 10px;
}

.popover-detail-button {

}

.plan-container {
  display: flex;
  height: calc(100vh - 8.4375rem);
  flex-direction: column;
}

.plan-board {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
}

.plan-column-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}

.plan-column {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  overflow: auto;
}

.plan-column-wrapper {
  display: flex;
  min-height: min-content;
  flex-direction: column;
  justify-content: flex-end;
}

.plan-column-title {
  text-align: center;
}

.plan-column-title > span {
  display: block;
  font-size: 2.38vh;
  line-height: 1.3;
  color: white;
  font-family: Product Sans;
  text-align: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
}

.plan-column-title:last-child > span {
  border-right: 1px solid white;
}

.plan-column-title-percentage-box {
  position: relative;
  background: white;
  border-left: 1px solid #bdc1c6;
  font-family: Product Sans;
  font-size: 2.98vh;
  line-height: 1.4;
}

.plan-column-title-percentage-box:last-child {
  border-right: 1px solid #bdc1c6;
}

.plan-column-title-percentage-box > input[type=checkbox] {
  position: absolute;
  left: 5px;
  height: 100%;
  padding: 3px;
}

.plan-column-title-inactive {
  background-color: #706e6b;
}

.plan-column-title-mid {
  background-color: #ffda83;
}

.plan-column-title-mid > span{
  color: gray;
}

.plan-column-title-regular {
  background-color: #52e1a3;
}

.plan-column-title-danger {
  background-color: var(--salmon-pink);;
}

.plan-column-percentage-inactive {
  color: #706e6b;
}

.plan-column-percentage-mid {
  color: #ffda83;
}

.plan-column-percentage-regular {
  color: #52e1a3;
}

.plan-column-percentage-danger {
  color: var(--salmon-pink);;
}

.plan-task {
  position: relative;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.plan-task-modal {
  position: absolute;
}

.plan-task-modal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tether-element .plan-task {
  width: 12vw;
}

.plan-task-urgent-header {
  background-color: var(--salmon-pink);
  font-family: Product Sans;
  font-size: 1.2vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.plan-task-close-modal {
  margin: 10px;
  cursor: pointer;
}

.plan-task-close-modal > img {
  height: 3vh;
  object-fit: contain;
}

.plan-task-tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 8%;
  height: calc(100% - 1px);
}

.plan-task-done {
}

.plan-task-done > .plan-task-header {
  background-color: #ffffff;
  color: #909090;
}

.plan-task-started .plan-task-header > img {
  background-color: #10d592;
}

.plan-task-header {
  font-family: Product Sans;
  font-size: 2.68vh;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  word-break: break-word;
  position: relative;
}

.plan-task-header span.completed {
  display: block;
  font-size: 1.38vh;
}

.plan-task-modal {
  max-width: 344px;
  display: flex;
  flex-direction: column;
}

.plan-task-modal .plan-task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-task-illegal-modal {
  max-width: 344px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.plan-task-illegal-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Product Sans;
  font-size: 2.68vh;
  line-height: 1.22;
  text-align: center;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  word-break: break-word;
  background-color: #636363;
}

.plan-task-illegal-modal-header > .fill {
  width: 3vh;
}

.plan-task-illegal-close-modal {
  margin: 10px;
  cursor: pointer;
}

.plan-task-illegal-close-modal > img {
  height: 3vh;
  object-fit: contain;
}

.plan-task-illegal-modal-reason {
  font-family: Product Sans;
  font-size: 2vh;
  color: #636363;
  padding: 0.5vh 2vh;
}

.plan-task-header > img {
  background-color: #ffffff;
  border-radius: calc(2.7vh/2);
  position: absolute;
  top: calc(50% - 1.35vh);
  left: 10px;
  padding: 0.5vh;
  height: 2.7vh;
  width: 2.7vh;
}


.plan-task-modal .plan-task-header > img {
  position: static;
  height: 4.34vh;
  width: 4.34vh;
  margin: 10px;
  top: calc(50% - 4.34vh / 2);
  border-radius: calc(4.34vh / 2);
}

.plan-task > span {
  font-family: Product Sans;
  font-size: 2.68vh;
  line-height: 1.22;
  text-align: left;
  color: #636363;
  padding: 8px;
  padding-top: 4px;
  white-space: pre-wrap;
  display: block;
  word-break: break-word;
}

.plan-task-modal-info {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px rgba(144, 144, 144, 0.34);
}

.plan-task-modal-info-progress {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  text-align: center;
}

.plan-task-modal-info-progress > span {
  font-family: Product Sans;
  font-size: 1.84vh;
  font-weight: bold;
  line-height: 1.21;
  color: #636363;
}

.in-progress.plan-task-modal-info-progress > span {
  color: #10d592;
}

.overdue.plan-task-modal-info-progress > span {
  color: var(--salmon-pink);
}

.plan-task-modal-info-progress-bar {
  height: 2.37vh;
  border-radius: 1.18vh;
  background-color: #909090;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.plan-task-modal-info-progress-bar > span {
  font-family: Product Sans;
  font-size: 2.35vh;
  line-height: 1;
  color: #ffffff;
  position: absolute;
}

.plan-task-modal-info-progress-ghost {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.in-progress .plan-task-modal-info-progress-ghost {
  background-color: #10d592;
}

.overdue .plan-task-modal-info-progress-ghost {
  background-color: var(--salmon-pink);
}

.plan-task-modal-info-timebox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.plan-task-modal-info-timebox > h4 {
  font-family: Product Sans;
  font-size: 2.63vh;
  font-weight: bold;
  line-height: 1.2;
  color: #636363;
  margin-bottom: 1px;
}

.plan-task-modal-info-timebox > span {
  font-family: Product Sans;
  font-size: 1.3vh;
  line-height: 1.17;
  color: #636363;
}

.plan-task-modal-body {
  font-family: Product Sans;
  font-size: 1.58vh;
  line-height: 1.17;
  text-align: left;
  color: #707070;
}

.plan-task-modal-button {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: solid 1px var(--salmon-pink);
  font-family: Product Sans;
  font-size: 1.58vh;
  line-height: 1.17;
  text-align: left;
  color: var(--salmon-pink);
  align-self: center;
  margin: 10px;
  padding: 5px;
}

.plan-task-finished {
  text-align: center;
  font-family: Product Sans;
  color: #706e6b;
  padding-top: 1vh;
}

.plan-task-finished > .message {
  font-family: Product Sans;
  font-size: 2.1vh;
  line-height: 1.19;
}

.plan-task-finished > .duration {
  font-size: 1.57vh;
  line-height: 1.17;
}

.plan-task-finished .image > img {
  height: 14.7vmin;
  width: 14.7vmin;
  margin: 1.5vh;
}

.plan-task-finished > .options {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.plan-task-finished > .options img {
  cursor: pointer;
}

.plan-task-complete-time {
  font-size: 3.2vh;
  color: var(--salmon-pink);
}

.plan-task-complete-time.ahead {
  color: #10d592;
}

/* .plan-task-tag-priority-0 {
  background-color: #5dabff;
}

.plan-task-tag-priority-1 {
  background-color: #80ce80;
}

.plan-task-tag-priority-2 {
  background-color: #d0d075;
}

.plan-task-tag-priority-3 {
  background-color: #daa0aa;
}

.plan-task-tag-priority-4 {
  background-color: #d0a14c;
}

.plan-task-tag-priority-5 {
  background-color: #c34242;
} */

.plan-task-bad {
  background-color: red;
}

.plan-task-bad:after {
	border-top-color: red;
}

.plan-days {
  display: flex;
  flex-direction: row;
  height: 6.7vh;
}

.plan-days-day {
  display: flex;
  flex: 1;
  color: white;
  padding: 3px;
  background-color: rgba(124, 142, 159, 0.8);
  border-top: 1px solid white;
  border-right: 1px solid white;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Product Sans;
  font-size: 2.9vh;
  min-width: 0;
}

.plan-days-day > span {
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.plan-days-day-selected {
  background-color: rgba(124, 142, 159, 1);
}

.plan-days-now {
  background-color: rgba(199, 68, 74, 0.8);
}

.plan-days-now-selected {
  background-color: rgba(199, 68, 74, 1);
}

.report-container {
  display: flex;
  flex: 1;
  height: calc(100vh - 8.4375rem);
  flex-direction: column;
  overflow: auto;
}

.report-header {
  position: relative;
  flex-shrink: 0;
}

.report-cols {
  display: flex;
  flex: 1 100%;
  flex-direction: row;
}

.report-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.report-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.report-flex-one {
  flex: 1 1 100%;
}

.report-right-side {
  flex: 1.5;
}

.report-side {
  flex: 2;
}

.report-mid {
  flex: 3;
}

.rank-gold-icon {
  height: 6.31vh;
  width: 6.31vh;
  position: absolute;
  left: 1vw;
}

.report-box {
  background-color: white;
  border-radius: 10px;
  margin: 1.38vh;
  color: #909090;
  overflow: none;
}

.report-table {
  width: 100%;
  margin-bottom: 1vh;
}

.report-table th {
  font-family: Product Sans;
  font-size: 1.32vh;
  color: #636363;
  padding: 0 1vw;
}

.report-table tbody {
  border-left: solid 1px #909090;
  border-right: solid 1px #909090;
}

.report-table tr {
  border-bottom: solid 1px #909090;
}

.report-table td {
  font-family: Product Sans;
  font-size: 1.45vh;
  color: #636363;
  padding: 0 1vw;
}

.report-table-colleagues img {
  height: 2vh;
  width: 2vh;
}

.report-table td span.critical {
  background-color: var(--salmon-pink);
  color: white;
}

.report-table td span.warning {
  background-color: #ffda83;
  color: white;
}

.report-table-colleagues img {
  height: 2vh;
  width: 2vh;
}

.report-box-header {
  margin: 0 1.3vw;
  border-bottom: solid 1px #909090;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.report-box-content span.yellow {
  font-size: 6.31vh;
  line-height: 1;
  color: #ffda83;
  margin-right: 1vw;
}

.report-box-content span.big {
  font-size: 2.63vh;
  line-height: 1;
}

.report-box-title {
  font-family: Product Sans;
  font-size: 2.63vh;
  text-align: left;
  color: #909090;
}

.report-box-subtitle {
  font-family: Product Sans;
  font-size: 1.32vh;
  text-align: left;
  color: #909090;
}

.report-box-tabs {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.report-box-tab {
  font-family: Product Sans;
  font-size: 1.32vh;
  text-align: left;
  color: #909090;
  padding: 3px;
  margin: 3px 0;
  cursor: pointer;
}

.report-box-tab-selected {
  background-color: #909090;
  color: white;
  border-radius: calc((1.32vh + 10px) / 2);
}

.report-box-content {
  margin: 0.5vh 1.3vw;
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
}

.report-box-transparent {
  padding: 10px;
}

.report-box-red {
  background-color: #E5B8B0;
}

.report-white-title {
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 1.2em;
  color: white;
}

.report-pie-percent {
  dominant-baseline: middle;
  text-anchor: middle;
  alignment-baseline: baseline;
  font-family: Product Sans;
  font-size: 4vh;
  fill: #5fe3a1;
}

.report-gauge-percent {
  dominant-baseline: middle;
  text-anchor: middle;
  alignment-baseline: baseline;
  font-family: Product Sans;
  font-size: 5vh;
  fill: #ffda83;
}

.report-pie-text {
  dominant-baseline: middle;
  text-anchor: middle;
  alignment-baseline: hanging;
  font-family: Product Sans;
  font-size: 2.5vh;
  fill: #909090;
}

.report-pie-chart-container {
  margin: 0 auto;
}

.report-chart-container {
  display: flex;
  flex-direction: row;
}

.report-chart-area {
  flex: 1;
}

.report-chart-sidebar {
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 115px;
}

.report-chart-sidebar-button {
  background-color: #8E7EC1;
  border: 1px solid black;
  margin-bottom: 5px;
  cursor: pointer;
  text-align: center;
}

.report-chart-sidebar-button-active {
  border: 2px solid red;
}

.report-select-container {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 25vw;
}

.report-event-title {
  font-size: 2.1em;
  font-weight: bold;
  color: white;
  text-align: center;
}

.report-box-pill {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: unset;
}

.report-box-pill-tab {
  padding: 2.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-box-pill-tab > img {
  height: 5.26vh;
}

.report-box-pill-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: Product Sans;
  font-size: 1.58vh;
  line-height: 1.17;
  text-align: left;
  color: #909090;
  justify-content: space-between;
}

.report-box-pill-tab.blue {
  background-color: #528be1;
}

.report-box-pill-tab.red {
  background-color: #c7444a;
}

.report-box-pill-tab.yellow {
  background-color: #ffda83;
}

.report-box-pill-header {
  font-family: Product Sans;
  font-size: 2.63vh;
  line-height: 1.1;
}

.report-box-pill-header.center {
  text-align: center;
}

.report-box-pill-header > span {
  font-size: 6.31vh;
  line-height: 1;
}

.report-box-pill-header > span.small {
  font-size: 3.42vh;
}

.report-box-pill-header > span.blue {
  color: #528be1;
}

.report-box-pill-header > span.red {
  color: #c7444a;
}

.report-box-pill-header > span.yellow {
  color: #ffda83;
}

.report-box-pill-pb {
  height: 1.32vh;
  width: 100%;
  border-radius: calc(1.32vh / 2);
  background-color: #f0f2f5;
  overflow: hidden;
}

.report-box-pill-pb-fill {
  width: 30%;
  height: 100%;
  border-radius: calc(1.32vh / 2);
}

.report-box-pill-pb-fill.blue {
  background-color: #528be1;
}

.report-box-pill-pb-fill.red {
  background-color: #c7444a;
}

.report-box-pill-pb-fill.yellow {
  background-color: #ffda83;
}

.dashboard-container.add-event {
  background: none;
  height: 90vh;
}

.dashboard-container.add-event a {
  color: inherit;
}

.event-form-container {
  display: flex;
  flex-direction: column;
}

.event-form {
  display: flex;
  flex-direction: row;
}

.event-form-col {
  flex: 1;
  overflow: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.task-form-title {
  margin-left: 10px;
}

.event-form-button-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-bottom: 10px;
}

.event-form-button {
  flex: 1;
  margin-right: 5px;
  margin-left: 5px;
}

.event-progress {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 25px;
}

.event-progress-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(193, 193, 193);
  z-index: 5;
}

.event-progress-circle.active {
  background-color: rgb(156, 201, 60);
  color: white;
}

.event-progress-line {
  flex: 1;
  background-color: rgb(193, 193, 193);
  height: 8px;
  margin-left: -8px;
  margin-right: -8px;
}

.event-progress-line.active {
  background-color: rgb(156, 201, 60);
}

.approval-list-container {
  min-width: 60%;
}

@media only screen and (max-width : 480px) {
  .report-cols {
    display: block;
  }
}

@media only screen and (max-width : 480px) {

  .runway-item-tip {
    width: 80vw;
  }

  /* .plan-days-day {
    font-size: 0.4em;
  } */

  .plan-column-title {
    font-size: 9px;
  }

  .plan-task {
    font-size: 9px;
  }

  .tether-element .plan-task {
    width: 60vw;
  }

  .runway-month-mark {
    transform: none;
  }

}

@media only screen and (min-device-width : 480px) and (max-width : 768px) {

  .runway-item-tip {
    width: 27vw;
  }

  .plan-days-day {
    font-size: 0.7em;
  }

  .plan-column-title {
    font-size: 11px;
  }

  .plan-task {
    font-size: 13px;
  }

  .tether-element .plan-task {
    width: 20vw;
  }

}
