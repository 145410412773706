@import './base.scss';
@import './colors.scss';

.titleRow > h2 {
  font-family: Product Sans;
  font-size: 35px;
  font-weight: bold;
  padding-top: 45px;
  padding-left: 60px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 45px 60px;
  padding-top: 20px;
  border-bottom: solid 1px rgba(#707070, 0.2);
  font-family: Product Sans;

  .headerInfo {
    flex: 2;

    h2 {
      font-size: 33px;
      font-weight: bold;
      line-height: 1.21;
      color: #3e8df1;
    }

    .infoItem {
      font-size: 26px;
      line-height: 1.23;
      color: #636363;
      margin-bottom: 0;
      margin-top: 22px;
    }
  }

  .headerSelect {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #171717;
      margin-bottom: 22px;
    }
  }
}

.container {
  font-family: Product Sans;
  margin: 30px 60px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.19fr;
  height: calc(100% - 310px);

  .top {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1.49fr 1fr;
    grid-template-rows: 1fr 1.41fr;

    .reportGraphContainer {
      grid-row: span 2;
    }
  }

  .bottom {
    display: grid;
    grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: 1.26fr 1fr 1.04fr 1.04fr;
  }

  & > div > div {
    border-radius: 5px;
    box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
  }
}

.keyMetrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: solid 1px #d3d3d3;

  & > div {
    border-right: solid 1px #d3d3d3;
  }

  & > div:last-child {
    border-right: none;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 25px;
  padding-left: 38px;
  padding-bottom: 24px;

  .title {
    font-size: 18px;
    line-height: 1.22;
    color: #636363;
  }

  .number {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    color: #434343;
  }

  .ticker {
    margin-left: 20px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  padding: 25px 35px;

  &.centered {
    justify-content: center;
  }

  &.noPadding {
    padding: 0;
    padding-top: 25px;
    padding-bottom: 25px;

    .boxHeading {
      padding-left: 35px;
    }

    .boxSubHeading {
      padding-left: 35px;
    }
  }
}

.boxHeading {
  font-size: 26px;
  font-weight: bold;
  color: #434343;
}

.boxSubHeading {
  font-size: 15px;
  color: #636363;
}

.eventsCompliance {
  display: flex;
  flex-direction: row;
  align-items: center;

  .legend {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-gap: 20px 20px;
    width: 100%;
    height: min-content;
    margin-right: 30px;
    font-size: 20px;
    color: #242424;

    .number {
      font-size: 18px;
      font-weight: bold;
      color: #4d4f5c;
    }
  }

  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
  }

  .donut {
    height: 120px;
    width: 120px;
  }
}

.todaysReport {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 38px;

    button {
      font-size: 14px;
      text-align: center;
      color: rgba(#000000, 0.37);
      border-radius: 3px;
      background-color: #ededed;
      margin-bottom: 20px;
      padding: 10px 0;
      border: none;
      cursor: pointer;

      &.active {
        color: white;
        background-color: #3e8df1;
      }
    }
  }

  .chart {
    flex: 2;
  }
}

.topAreas {
  flex: 1;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .row {
    display: flex;
    flex-direction: row;

    &.border {
      flex: 1;
      align-items: center;
      border-bottom: solid 1px rgba(#707070, 0.27);
    }

    &.border:last-child {
      border-bottom: unset;
    }
  }

  .name {
    flex: 1;
    font-size: 18px;
    line-height: 1.72;
    color: #191919;
  }

  .time {
    font-size: 18px;
    line-height: 1.72;
    color: #191919;
  }
}

.noPadding .row {
  padding-left: 35px;
}
