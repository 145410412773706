@import './colors.scss';
@import './base.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: px-to-rem(60px);
}

.filterBar {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-top: solid 1px rgba(#707070, 0.15);
  padding: px-to-rem(25px) px-to-rem(42px);

  & > select {
    font-size: px-to-rem(18px);
    margin-right: 2%;
  }

  .button {
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #3e8df1;
    font-family: Product Sans;
    font-size: px-to-rem(18px);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

  }
}

.title {
  font-family: Product Sans;
  font-size: px-to-rem(41px);
  color: #000000;
  text-align: center;
  margin-top: px-to-rem(59px);
  margin-bottom: px-to-rem(59px);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 441px);
  justify-content: center;
  grid-gap: 31px 35.6px;
  margin-left: 30px;
  margin-right: 30px;
}

.eventCard {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  & > div, & > h3 {
    padding-left: px-to-rem(33px);
    padding-right: px-to-rem(33px);
  }
}

.header {
  display: flex;
  flex-direction: row;
  margin-top: px-to-rem(25px);
  justify-content: space-between;
}

.tag {
  font-family: Product Sans;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.27;
  letter-spacing: 0.5px;
  color: #48aca9;
  text-transform: uppercase;

  img {
    width: px-to-rem(33.3px);
    height: px-to-rem(32.7px);
    object-fit: contain;
    margin-right: px-to-rem(8.5px);
  }
}

.cardMenu {
  cursor: pointer;

  img {
    width: px-to-rem(25.6px);
    height: px-to-rem(6px);
    object-fit: contain;
  }
}

.dropdownContainer {
  position: relative;
  align-self: flex-end;
  visibility: hidden;
  transition-property: opacity, transform, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  opacity: 0;
  transform: translateY(- px-to-rem(10px));
  z-index: 60 * 24 + 15;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: px-to-rem(168.6px);
  top: 0;
  right: calc(50% - 168.6px / 2);
  border-radius: 4px;
  -webkit-backdrop-filter: blur(44.7px);
  backdrop-filter: blur(44.7px);
  box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.33);
  background-color: #ffffff;
  padding: px-to-rem(10px);

  a {
    flex: 1;
    display: block;
    color: black;
    white-space: nowrap;
    font-family: Product Sans;
    font-size: px-to-rem(15px);
    line-height: 1.2;
    color: #393939;
    margin-bottom: px-to-rem(12px);

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: inline-block;
      height: px-to-rem(16px);
      object-fit: contain;
      margin-right: px-to-rem(13px);
    }
  }
}

.cardTitle {
  font-family: Product Sans;
  font-size: px-to-rem(25px);
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
  margin-top: px-to-rem(18px);
  margin-bottom: 0;
}

.nameTag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: px-to-rem(20px);
  padding-bottom: px-to-rem(20px);
  border-bottom: solid 1px rgba(#707070, 0.15);

  .initials {
    $size: px-to-rem(50px);
    margin-right: px-to-rem(15px);
    width: $size;
    height: $size;
    border-radius: $size / 2;
    background-color: #ffe0e6;
    font-family: Product Sans;
    font-size: px-to-rem(22px);
    line-height: $size;
    letter-spacing: 1.1px;
    text-align: center;
    color: #b93f56;
  }

  .name {
    font-family: Product Sans;
    font-size: px-to-rem(18px);
    line-height: 1.22;
    color: #4e4e4e;
  }
}

.date, .team {
  font-family: Product Sans;
  font-size: px-to-rem(18px);
  line-height: 1.22;
  color: #4e4e4e;

  img {
    width: px-to-rem(41.4px);
    height: px-to-rem(41.4px);
    object-fit: contain;
    margin-right: px-to-rem(14px);
  }
}

.date {
  margin-top: px-to-rem(19px);
}

.team {
  margin-top: px-to-rem(11px);
  padding-bottom: px-to-rem(19px);
  border-bottom: solid 1px rgba(#707070, 0.15);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: px-to-rem(21px);
  margin-bottom: px-to-rem(21px);
  font-family: Product Sans;
  font-size: px-to-rem(20px);

  .progress {
    color: #414141;
  }

  .urgentness {
    color: #ff4343;
  }
}
