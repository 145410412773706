@import './base.scss';
@import './colors.scss';

.background {
  background: url(../assets/images/login-background-left@3x.png), url(../assets/images/login-background-right@3x.png);
  background-size: 270px, 270px;
  background-repeat: no-repeat, no-repeat;
  background-position: calc(50vw - 365px) calc(50vh - 150px), calc(50vw + 55px) calc(50vh - 330px);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include mobile {
    background-position: calc(50vw) calc(50vh + 100px), calc(50vw - 280px) calc(50vh - 350px);
  }
}

.logo {
  margin-bottom: 1.3rem;

  @include mobile {
    position: absolute;
    top: 32px;
  }
}

.loginBox {
  box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  width: 90vw;
  max-width: 405px;
  padding: {
    top: 64px;
    bottom: 64px;
  }
  position: relative;

  @include mobile {
    width: 85vw;
    padding: {
      top: 51px;
      bottom: 51px;
    }
  }
}

.alert {
  position: absolute;
  width: 80%;
  top: 6.5rem;
  left: 10%;
  @include mobile {
    top: 85px;
  }
}

.title {
  font-family: Product Sans;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.81;
  letter-spacing: 0.2px;
  color: #484141;
  margin-bottom: 4rem;

  @include mobile {
    margin-bottom: 40px;
  }
}

.form {

  input {
    $font-size: 15px;
    $horizontalMargin: 64px;
    border: none;
    border-radius: 0;
    border-bottom: rgba(#d5d5d5, 0.78) 1px solid;
    font-family: Product Sans;
    font-size: $font-size;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 0.2px;
    text-align: left;
    color: #484141;
    background-repeat: no-repeat;
    background-position: 0.5rem 0.5rem;
    padding-left: 3.5rem;
    background-size: 1.47rem;
    width: calc(100% - #{$horizontalMargin * 2});
    margin: {
      top: $font-size * 2;
      bottom: $font-size * 2;
      left: $horizontalMargin;
      right: $horizontalMargin;
    }

    @include mobile {
      $horizontalMargin: 25px;
      width: calc(100% - #{$horizontalMargin * 2});
      margin: {
        top: $font-size * 2;
        bottom: $font-size * 2;
        left: $horizontalMargin;
        right: $horizontalMargin;
      }
    }

    &::placeholder {
      opacity: 1;
      color: #484141;
    }

    &.employeeId {
      background-image: url(../assets/images/employee-id-icon@3x.png);
    }

    &.password {
      background-image: url(../assets/images/password-icon@3x.png);
    }
  }

}

.button {
  width: 206.6px;
  height: 40.7px;
  border-radius: 42px;
  background-image: linear-gradient(to bottom, #10d592, #4dad88);
  font-family: Product Sans;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: 0.05rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-top: 54px;

  @include mobile {
    margin-top: 15px;
  }

  &:focus {
    outline: none;
  }
  &:hover {
    background-image: linear-gradient(to bottom, lighten(#10d592, 10%), lighten(#4dad88, 10%));
  }
  &:active {
    padding: inherit;
    background-image: linear-gradient(to bottom, lighten(#4dad88, 4%), lighten(#10d592, 4%));
  }
}
