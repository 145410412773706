@import './colors.scss';
@import './base.scss';

@mixin add-type-colors($property) {
  @each $type, $color in $task-colors {
    &.#{$type} {
      #{$property}: $color;
    }
  }
}

.planBoard {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  max-width: $max-width;
  max-height: px-to-rem($max-height) - $nav-total-height;
  align-self: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  @include mobile {
    background-color: white;
  }
}

.planBoardScroll {
  margin: auto;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  // max-width: 100%;
  // max-height: 100%;
  // padding-right: px-to-rem(30px);
}

.planBoardFlex {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: min-content;
}

.columnContainer {
  display: flex;
  flex: 1 1;
  max-width: px-to-rem(230px);
  flex-direction: column;
  margin-left: px-to-rem(10px);
  margin-right: px-to-rem(10px);
  margin-top: px-to-rem(40px);
  margin-bottom: px-to-rem(40px);
  background-color: white;
  border-radius: 5px;
  min-width: px-to-rem(169px);

  @include mobile {
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-top: px-to-rem(15px);
    min-width: 277px;
    margin-bottom: 0;
    width: 73vw;
  }

  &:first-child {
    margin-left: px-to-rem(30px);
  }

  &:last-child {
    margin-right: px-to-rem(30px);
  }
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  overflow: auto;
}

.columnTitle {
  font-family: Product Sans;
  font-size: px-to-rem(16px);
  line-height: 1.19;
  text-align: center;
  color: #000000;
  padding-right: px-to-rem(17px);
  padding-left: px-to-rem(17px);
  padding-top: px-to-rem(17px);
  border-bottom: 2px solid rgba(black, 0.09);

  input {
    float: right;
  }

  .percentage {
    font-family: Product Sans;
    font-size: px-to-rem(19px);
    font-weight: bold;
    line-height: 1.11;
    text-align: center;
    margin-top: px-to-rem(10px);
    margin-bottom: px-to-rem(10px);

    &.inactive {
      color: #706e6b;
    }

    &.mid {
      color: #ffda83;
    }

    &.regular {
      color: #52e1a3;
    }

    &.danger {
      color: var(--salmon-pink);;
    }
  }

}

.task {
  position: relative;
  overflow: hidden;
  margin-left: px-to-rem(20px);
  margin-right: px-to-rem(20px);
  margin-top: px-to-rem(7px);
  margin-bottom: px-to-rem(7px);
  background-color: #ffffff;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.15);
  border-width: 2px;
  border-style: solid;

  @include add-type-colors(border-color);

  &:first-child {
    margin-top: px-to-rem(17px);
  }

  &:last-child {
    margin-bottom: px-to-rem(17px);
  }

  &.done {
    background-color: rgba(black, 0.1);
  }

  &.bad {
    background-color: rgb(255, 185, 170);
  }
}

.taskHeader {
  display: flex;
  flex-direction: row;
  font-family: Product Sans;
  font-size: px-to-rem(13px);
  font-weight: bold;
  line-height: 1.15;
  text-align: left;
  margin-top: px-to-rem(10px);
  margin-left: px-to-rem(22px);
  margin-right: px-to-rem(10px);

  .taskHeaderTime {
    display: block;
    flex: 1;

    @include mobile {
      font-size: px-to-rem(14px);
    }
  }

  img {
    width: px-to-rem(10px);
    height: px-to-rem(10px);
    object-fit: contain;

    @include mobile {
      width: px-to-rem(16px);
      height: px-to-rem(16px);
    }
  }
}

.urgentHeader {
  font-family: Product Sans;
  font-size: px-to-rem(8px);
  line-height: 1.25;
  text-align: center;
  color: #ffffff;

  @include add-type-colors(background-color);

}

.taskName {
  display: block;
  font-family: Product Sans;
  font-size: px-to-rem(11px);
  margin-left: px-to-rem(22px);
  margin-right: px-to-rem(10px);
  margin-top: px-to-rem(12px);
  margin-bottom: px-to-rem(16px);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #606060;

  @include mobile {
    font-size: 15px;
    margin-top: px-to-rem(20px);
  }
}

.illegalModal {
  max-width: px-to-rem(355.6px);
  display: flex;
  flex-direction: column;
  border: 3px solid #ffa7a5;
  border-radius: 5px;
  box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.36);
  background-color: #ffffff;
  align-items: center;
  text-align: center;
  padding-top: px-to-rem(32px);
  padding-bottom: px-to-rem(51px);

  .closeButton {
    @include reset-input;
    $offset: px-to-rem(19px);
    position: absolute;
    top: $offset;
    right: $offset;
    cursor: pointer;

    img {
      $size: px-to-rem(20.8px);
      width: $size;
      height: $size;
      object-fit: contain;
    }
  }

  .illegalMoveIcon {
    $size: px-to-rem(82.2px);
    width: $size;
    height: $size;
    object-fit: contain;
    margin-bottom: px-to-rem(34px);
  }

  h3 {
    font-family: Product Sans;
    font-size: px-to-rem(20px);
    font-weight: bold;
    line-height: 1.2;
    color: #363636;
    margin-bottom: px-to-rem(26px);
  }

  .illegalReason {
    font-family: Product Sans;
    font-size: px-to-rem(15px);
    line-height: 1.2;
    color: #5d5d5d;
    width: 60%;
    margin-bottom: px-to-rem(8px);
  }
}

.checkbox {
  float: right;
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-left: 13px;
    display: inline-block;
    vertical-align: text-top;
    width: 17px;
    height: 17px;
    background: white;
    border-radius: 2px;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.12);
  }

  // Box hover
  &:hover + label:before {
    background: #2699fb;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: inset 0 0 2px 1px rgba(#2699fb, 0.32);
  }

  // Box checked
  &:checked + label:before {
    // background: #2699fb;
    box-shadow: unset;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 13px;
    top: 0;
    width: 17px;
    height: 17px;
    background-color: white;
    background-image: url(../assets/images/checkbox-checked@3x.png);
    background-size: 17px 17px;
  }
}
