@import './colors.scss';
@import './base.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: px-to-rem(57px);
  font-family: Product Sans;
}

.title {
  font-size: px-to-rem(41px);
  line-height: 1.2;
  text-align: left;
  color: #000000;
  margin: 0;
  margin-bottom: px-to-rem(32px);
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  background-color: #ffffff;
  padding: 4% 5%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
}

.controls {
  display: flex;
  flex-direction: row;

  .button {
    margin-right: 55px;
    cursor: pointer;

    img {
      width: 12.5px;
      height: 22px;
      object-fit: contain;
    }
  }
}

.date {
  font-size: 41px;
  font-weight: bold;
  color: #44a0ed;
}

.tabs {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  border-radius: 5px;
  border: solid 1px #3e8df1;

  .tab {
    font-size: 25px;
    color: #888888;
    padding: 10px 27px;
    cursor: pointer;
  }

  .tab.active {
    background-color: #3e8df1;
    color: white;
  }
}

.dayNames {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & > div {
    font-size: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: #434343;
    overflow: hidden;

    span {
      display: block;
      font-size: 23px;
      opacity: 0.24;
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1px;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
}

.calendar > div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: white;
}

.day::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / 1.5);
  grid-column: 1 / 1;
}

.day {
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-family: Montserrat;
  font-size: 1.4vw;
  color: #9d9d9d;

  span {
    margin-right: 5px;
    margin-bottom: 5px;

    &.today {
      color: white;
      background-color: black;
      border-radius: 8px;
      padding: 4px 7px;
    }
  }
}

.day.notInWeek {
  color: rgba(#8e8e8e, 0.4);
  background-color: #F5F5F6;
}

.day.active {
  background-color: #ebf8ff;
  color: #44a0ed;
  cursor: pointer;

  &:hover {
    background-color: darken(#ebf8ff, 3%);
  }
}

.eventBand {
  font-family: Product Sans;
  position: absolute;
  display: flex;
  align-items: center;
  left: 10px;
  top: calc(50% - 58px / 1.5);
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fb7ea4;
  font-size: 20px;
  color: #ffffff;
  height: 58px;
  padding-left: 8px;

  .iconContainer {
    border-radius: 3px;
    background-color: rgba(#ffffff, 0.3);
    width: 42px;
    height: 42px;
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .card {
    width: 440px;
  }
}

.week {
  position: relative;
  height: 645px;
}

.weekEventContainer {
  position: absolute;
  margin: 3px;
  height: 106px;
  padding: 5px;
}

.weekEvent {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fb7ea4;
  font-size: 20px;
  height: 100%;
  color: #ffffff;
  font-size: 23px;
  padding-left: 15px;

  .iconContainer {
    border-radius: 3px;
    background-color: rgba(#ffffff, 0.3);
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}
