@import '../colors.scss';
@import '../base.scss';

.container {
  display: grid;
  grid-gap: 0;
  height: 100%;

  & > div {
    background: white;
  }
}

.tick {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border-right: solid 1px #d2d2d2;
  font-family: Moderat;
  font-size: 11px;
  line-height: 1.18;
  color: #706e6b;
  padding-right: 5px;
}

.label {
  border-top: solid 1px #d2d2d2;
  text-align: center;
  font-family: Moderat;
  font-size: 11px;
  line-height: 1.18;
  color: #706e6b;
}

.barContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.bar {
  width: 70%;
  background-color: #e3e3e3;
}
