@import './base.scss';
@import './colors.scss';

.container {
  display: inline-block;
  font-family: Product Sans;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2px;
  vertical-align: middle;

  .innerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 33px;
    width: 81.8px;
    margin: 0 7px;

    img {
      width: 16.2px;
      height: 12px;
      object-fit: contain;
      vertical-align: middle;
      margin-right: 7px;
    }
  }
}

.up {
  background-color: #d6fff1;
  color: #10d592;
}

.down {
  background-color: #fff0f3;
  color: #e53c5c;
}

.same {
  background-color: #fffdd6;

  & > .innerContainer > div {
    width: 40.4px;
    height: 6.6px;
    border-radius: 20px;
    background-color: #f4c24d;
  }
}
