@import './colors.scss';
@import './base.scss';

.activeNow {
  color: #fb8888 !important;
}

.container {
  flex: 1;
  background-color: white;
  height: calc(100vh - #{$nav-total-height});
}

.innerContainer {
  flex: 1;
  position: relative;
  flex-direction: column;
  display: flex;
  height: calc(100vh - #{$nav-total-height});
  max-height: px-to-rem($max-height) - $nav-total-height;
  background-color: white;
  padding-bottom: px-to-rem(29px);
}

.perspectiveContainer {
  flex: 1;
  align-self: center;
  width: calc(100% - #{2 * $timeline-controls-horizontal-margin});
  max-width: $max-width;
  position: static;
  perspective: 150px;
  perspective-origin: top;
  display: flex;

  @include mobile {
    width: calc(100% - #{2 * $timeline-controls-horizontal-margin-mobile});
  }
}

.perspective {
  display: flex;
  flex: 1;
  transform: rotateX(20deg);
  transform-origin: bottom;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
  position: relative;
}

.perspectiveTiles {
  flex: 1;
  border-right: 1px solid #9dc6ff;
  background-color: #ddeeff;
  position: relative;

  &:last-of-type {
    border-right: none;
  }
}

.gridLineTether {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #9dc6ff;
}

.gridLine {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: px-to-rem(20px);
  transform: translateX(px-to-rem(-14px));
  font-family: Product Sans;
  font-size: px-to-rem(17px);
  line-height: 1.18;
  text-align: center;
  color: #909090;

  span {
    margin-right: 1.5vw;
  }

  @include mobile {
    font-size: px-to-rem(12px);
    transform: translateX(px-to-rem(-4px));
    margin-right: 0;
  }
}

.nowGridLineTether {
  display: flex;
  align-items: center;
  background-color: #347fe5;
  height: 1px;

  &:before {
    content: "";
    display: inline-block;
    border-style: solid;
    border-width: px-to-rem(5px) 0 px-to-rem(5px) px-to-rem(10px);
    border-color: transparent transparent transparent #007bff;
  }
}

.titlesContainer {
  width: calc(100% - #{2 * $timeline-controls-horizontal-margin});
  max-width: $max-width;
  align-self: center;
  display: flex;
  flex-direction: row;
  border-left: 1px solid $timeline-controls-border;
  position: relative;

  @include mobile {
    width: calc(100% - #{2 * $timeline-controls-horizontal-margin-mobile});
  }

  .title {
    flex: 1;
    text-align: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $timeline-controls-border;
    font-family: Product Sans;
    font-size: px-to-rem(16px);
    font-weight: bold;
    line-height: 1.19;
    text-align: center;
    color: #000000;
    padding: 10px 0;

    @include mobile {
      flex-direction: row;
      justify-content: center;
      font-size: px-to-rem(15px);
    }

    span {
      margin-bottom: px-to-rem(6px);

      @include mobile {
        margin-bottom: px-to-rem(0px);
      }
    }

    img {
      width: px-to-rem(20px);
      height: px-to-rem(20px);
      object-fit: contain;

      @include mobile {
        width: px-to-rem(16px);
        height: px-to-rem(16px);
        margin-left: 10px;
      }
    }
  }
}

.laneSwitchArrow {
  position: absolute;
  top: px-to-rem(17px);
  opacity: 1;
  cursor: pointer;
  $size: px-to-rem(23px);
  $offset: px-to-rem(6.5px);

  &.left {
    left: -$size - $offset;
  }

  &.right {
    right: -$size - $offset;
  }

  img {
    width: $size;
    height: $size;
    object-fit: contain;
  }

  @include mobile {
    margin: 0 $timeline-controls-horizontal-margin-mobile;
    $size: px-to-rem(26.5px);
    $offset: px-to-rem(8.5px);
    width: $size;
    height: $size;
    top: px-to-rem(5px);

    &.left {
      left: -$size - $offset;
    }

    &.right {
      right: -$size - $offset;
    }
  }
}

.runway-lane-switch-arrow > img {
  $size: px-to-rem(23px);
  width: $size;
  height: $size;
  object-fit: contain;
}

.arrow-left {
  left: -4vh;
}

.arrow-right {
  right: -4vh;
}

// TODO: Move these to a Task module along with the plan ones
@for $lanes from 2 through 10 {
  .per-#{$lanes} {
    $offset: 2 * $timeline-controls-horizontal-margin / $lanes;
    $offset2: 2 * $timeline-controls-horizontal-margin;
    $ratio: 0.7;
    width: calc((var(--max-width, 100vw) - #{$offset}) * #{$ratio} / #{$lanes});
    min-height: calc(((var(--max-width, 100vw) - #{$offset}) * #{$ratio} / #{$lanes}) * 0.6);
  }
}

.per-1 {
  @include mobile {
    $offset: 2 * $timeline-controls-horizontal-margin-mobile;
    width: calc(60vw - #{$offset});
    min-height: calc(60vw * 0.6 - #{$offset});
  }
}

@for $lanes from 2 through 10 {
  .runway-per-#{$lanes} {
    $offset: 2 * $timeline-controls-horizontal-margin / $lanes;
    $offset2: 2 * $timeline-controls-horizontal-margin;
    $ratio: 0.7;
    width: calc((var(--max-width, 100vw) - 16vw - #{$offset}) * #{$ratio} / #{$lanes});
    min-height: calc(((var(--max-width, 100vw) - 16vw - #{$offset}) * #{$ratio} / #{$lanes}) * 0.6);
  }
}

.runway-per-1 {
  @include mobile {
    $offset: 2 * $timeline-controls-horizontal-margin-mobile;
    width: calc(60vw - #{$offset});
    min-height: calc(60vw * 0.6 - #{$offset});
  }
}

@mixin add-type-colors($property) {
  @each $type, $color in $task-colors {
    &.#{$type} {
      #{$property}: $color;
    }
  }
}

.taskPanel {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.15);
  border-width: 2px;
  border-style: solid;

  @include add-type-colors(border-color);

  &.started > span {
    font-weight: bold;
    color: black;
  }

  &.completed {
    background-color: rgba(230, 230, 230, 0.6);
  }
}

// LANE INFO

.laneInfoParent {
  width: 418px;
  height: 278px;
  z-index: 1470;

  @include mobile {
    width: 86vw;
    height: calc(90vh - 87px);
    z-index: 150;
  }

  .closeButton {
    @include reset-input;
    visibility: hidden;
    position: absolute;
    $offset: px-to-rem(-17.65px);
    transform: translate3d(- $offset, $offset, 0);
    top: 0;
    right: 0;
    background-color: rgba(#6e6e6e, 0.93);
    width: 35.3px;
    height: 35.3px;
    border-radius: 17.65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include mobile {
      visibility: visible;
      z-index: 151;
    }

    img {
      filter: invert(100%);
      $size: px-to-rem(20px);
      width: $size;
      height: $size;
    }
  }
}

.laneInfoContainer {
  width: 418px;
  height: 278px;
  position: absolute;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.45);
  background-color: #ffffff;
  padding-right: px-to-rem(20px);
  padding-left: px-to-rem(20px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include mobile {
    width: 86vw;
    height: calc(90vh - 87px);
    z-index: 150;
    border-radius: 6px;
  }
}

.laneInfoHeader {
  display: flex;
  flex-direction: row;
  padding-top: px-to-rem(19.5px);
  padding-bottom: px-to-rem(13.5px);
  border-bottom: 1px solid rgba(#707070, 0.08);

  @include mobile {
    flex-direction: column;
  }

  .personInfoBox {
    display: flex;
    flex-direction: row;
    flex: 1;

    .avatar {
      $size: px-to-rem(49.3px);
      width: $size;
      height: $size;
      border-radius: $size / 2;
    }

    .nameInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 px-to-rem(15px);

      h3 {
        font-family: Product Sans;
        font-size: px-to-rem(18px);
        line-height: 1.22;
        color: #000000;
        margin-bottom: px-to-rem(7px);
      }

      span {
        display: block;
        font-family: Product Sans;
        font-size: px-to-rem(13px);
        line-height: 1.15;
        color: #2ca349;
      }
    }
  }

  .charts {
    display: flex;
    flex-direction: row;

    @include mobile {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .chartContainer {
      display: flex;
      flex-direction: column;
      margin-right: px-to-rem(30px);

      @include mobile {
        margin: 0;
        flex: 1;
        align-items: center;

        &:first-child {
          border-right: 1px solid rgba(#707070, 0.08);
        }
      }

      .chart {
        width: px-to-rem(44px);
        height: px-to-rem(44px);
        margin-bottom: px-to-rem(5px);

        @include mobile {
          width: px-to-rem(65px);
          height: px-to-rem(65px);
        }
      }

      .title {
        font-family: Product Sans;
        font-size: px-to-rem(9px);
        line-height: 1.22;
        text-align: center;
        color: #909090;
        display: block;

        @include mobile {
          font-size: px-to-rem(13px);
        }
      }
    }
  }
}

.laneInfoContent {
  display: flex;
  flex-direction: row;
  margin-top: px-to-rem(14px);
  margin-bottom: px-to-rem(25.5px);
  min-height: px-to-rem(145px);

  @include mobile {
    flex-direction: column;
  }

  .tasksColumn {
    flex: 1;
    display: flex;
    flex-direction: row;

    @include mobile {
      margin-top: 10px;
      padding-bottom: 10px;
    }

    &:first-child {
      border-right: 1px solid rgba(#707070, 0.08);
      padding-right: px-to-rem(22.5px);

      @include mobile {
        border-right: 0 solid rgba(#707070, 0.08);
        border-bottom: 1px solid rgba(#707070, 0.08);
        padding-right: 0;
      }
    }

    &:last-child {
      padding-left: px-to-rem(22.5px);

      @include mobile {
        padding-left: 0;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      font-family: Product Sans;
      line-height: 1.21;
      text-align: right;
      color: #2699fb;
      margin-right: px-to-rem(18px);

      .number {
        font-size: px-to-rem(24px);
        font-weight: bold;
      }

      .text {
        font-size: px-to-rem(16px);
      }
    }

    .tasks {
      flex: 1;
      font-family: Product Sans;
      font-size: px-to-rem(11px);
      line-height: 1.55;
      text-align: left;
      color: rgba(#909090, 0.57);

      span {
        display: block;
      }
    }
  }
}

.laneInfoArrow {

  img {
    width: 24px;
    height: 8px;
    object-fit: contain;
  }
}

.sliderContainer {
  width: calc(100% - #{2 * $timeline-controls-horizontal-margin});
  max-width: $max-width;
  align-self: center;

  @include mobile {
    width: calc(100% - #{2 * $timeline-controls-horizontal-margin-mobile});
  }
}
