$dark-text-color: #484141;

$task-colors: (
  Operational: #636363,
  Promotional: #2699fb,
  Commercial: #10d592,
  Local: #a3a1fb,
  Seasonal: #56d9fe,
  Special: #fec656
);

$timeline-controls-border: rgba(#aaaaaa, 0.37);
