body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: Moderat;
  src: url('./assets/fonts/Moderat-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/Moderat-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: Moderat;
  src: url('./assets/fonts/Moderat-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/Moderat-Bold.woff') format('woff');
  font-weight: bold;
}

:root {
  --salmon-pink: #ff7576;
}
